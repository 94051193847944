import { useStorage } from "@vueuse/core";

export const useFetchCitiesByZipCode = () => {
  const cachedCities = useStorage<Record<string, string[]>>("citiesByZip", {});
  const { $brain } = useNuxtApp();

  const fetchCitiesByZipCode = async (zipCode: string) => {
    console.log("fetching cities for zipCode", zipCode);
    if (zipCode.length === 5) {
      const cachedCitiesForZipCode = cachedCities.value[zipCode];
      if (cachedCitiesForZipCode === undefined) {
        try {
          const response = await $brain.public.tariffs.getCities(zipCode);
          cachedCities.value[zipCode] = response.data.items;
        } catch (error) {
          console.error(`Failed to fetch cities for zipCode ${zipCode}`, error);
        }
      }
    }

    return cachedCities.value[zipCode] ?? [];
  };

  return { fetchCitiesByZipCode };
};

export default useFetchCitiesByZipCode;
